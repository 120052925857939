import React, { useState, useEffect, useCallback, useRef } from "react";

const CustomCursor = () => {
  const dotRef = useRef(null);
  const outlineRef = useRef(null);
  const [hidden, setHidden] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [outlinePosition, setOutlinePosition] = useState({ x: 0, y: 0 });
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const updatePosition = useCallback((e) => {
    const { clientX, clientY } = e;
    setPosition({ x: clientX, y: clientY });
  }, []);

  const handleMouseDown = useCallback(() => {
    setClicked(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setClicked(false);
  }, []);

  const animateOutline = useCallback(
    (time) => {
      if (previousTimeRef.current !== undefined) {
        setOutlinePosition((prev) => ({
          x: prev.x + (position.x - prev.x) * 0.2,
          y: prev.y + (position.y - prev.y) * 0.2,
        }));
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animateOutline);
    },
    [position]
  );

  const requestRef = useRef();
  const previousTimeRef = useRef();

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animateOutline);
    return () => cancelAnimationFrame(requestRef.current);
  }, [animateOutline]);

  useEffect(() => {
    // Check if it's a touch device
    const checkTouchDevice = () => {
      setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
    };

    checkTouchDevice();
    window.addEventListener('resize', checkTouchDevice);

    return () => {
      window.removeEventListener('resize', checkTouchDevice);
    };
  }, []);

  useEffect(() => {
    const handleMouseEnter = () => setHidden(false);
    const handleMouseLeave = () => {
      setHidden(true);
      setPosition({ x: 0, y: 0 });
    };

    document.addEventListener("mousemove", updatePosition);
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mouseenter", handleMouseEnter);
    document.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.removeEventListener("mousemove", updatePosition);
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mouseenter", handleMouseEnter);
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [updatePosition, handleMouseDown, handleMouseUp]);

  if (hidden || isTouchDevice) return null;

  return (
    <>
      <style jsx>{`
        @media (hover: none) and (pointer: coarse) {
          .cursor-dot, .cursor-outline {
            display: none;
          }
        }
      `}</style>
      <div
        ref={dotRef}
        className={`cursor-dot ${clicked ? "cursor-dot-clicked" : ""}`}
        style={{
          top: position.y,
          left: position.x,
        }}
      />
      <div
        ref={outlineRef}
        className="cursor-outline"
        style={{
          top: outlinePosition.y,
          left: outlinePosition.x,
        }}
      />
    </>
  );
};

export default CustomCursor;
