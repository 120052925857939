import React, { useMemo } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";

import scroll1 from "../../assets/BrandLogospng/slashon.agencyworkfor2mynds.png";
import scroll2 from "../../assets/BrandLogospng/slashon.agencyworkforAugnito.png";
import scroll3 from "../../assets/BrandLogospng/slashon.agencyworkforAuxitech.png";
import scroll4 from "../../assets/BrandLogospng/slashon.agencyworkforCashkaro.png";
import scroll5 from "../../assets/BrandLogospng/slashon.agencyworkforCentrehive.png";
import scroll6 from "../../assets/BrandLogospng/slashon.agencyworkforEarlysteps.png";
import scroll7 from "../../assets/BrandLogospng/slashon.agencyworkforMakhanawalas.png";
import scroll8 from "../../assets/BrandLogospng/slashon.agencyworkforMarkSafety.png";
import scroll9 from "../../assets/BrandLogospng/slashon.agencyworkforMotherdairy.png";
import scroll10 from "../../assets/BrandLogospng/slashon.agencyworkforThePropertyExperts.png";
import scroll11 from "../../assets/BrandLogospng/slashon.agencyworkforTherising.png";
import scroll12 from "../../assets/BrandLogospng/slashon.agencyworkforVenusacademy.png";

const SliderHome = () => {
  const autoScrollItems = useMemo(
    () => [
      scroll1,
      scroll2,
      scroll3,
      scroll4,
      scroll5,
      scroll6,
      scroll7,
      scroll8,
      scroll9,
      scroll10,
      scroll11,
      scroll12,
    ],
    []
  );

  return (
    <div className="py-3 max-w-full overflow-hidden">
      <Splide
        options={{
          type: "loop",
          autoplay: true,
          interval: 3200,
          pauseOnHover: false,
          speed: 500,
          easing: "ease-in-out",
          perPage: 5,
          gap: "1rem",
          arrows: false,
          pagination: false,
          rewind: true,
        }}
      >
        {autoScrollItems.map((image, index) => (
          <SplideSlide key={index}>
            <img
              className="h-14 md:h-20 xl:h-32 w-auto transition-transform duration-700 ease-in-out will-change-transform"
              src={image}
              alt=""
              loading="lazy"
              preload="auto"
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default SliderHome;
