import React, { useState, useRef, useEffect } from "react";
import { GoArrowDown, GoArrowUpRight } from "react-icons/go";
import AboutProfile from "../../assets/About Page gifs/Aboutprofile.png";
import { text } from "../../data/Aboutdata.js";

const Aboutdetail = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const contentRef = useRef(null);

  const toggleVisibility = () => {
    setIsExpanded(!isExpanded);
    setIsButtonVisible(false);
  };

  useEffect(() => {
    if (contentRef.current) {
      if (isExpanded) {
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
      } else {
        contentRef.current.style.maxHeight = "0px";
      }
    }
  }, [isExpanded]);

  const FounderImage = () => (
    <div
      className={`rounded-2xl mb-20 relative lg:block hidden transition-transform duration-500 ease-in-out ${
        isExpanded ? "translate-y-16" : "translate-y-0"
      }`}
    >
      <div
        style={{
          clipPath:
            "polygon(50% 0%, 100% 0, 100% 35%, 100% 78%, 41% 78%, 25% 100%, 0 100%, 0% 70%, 0% 35%, 0 0)",
        }}
        className="bg-themelightblack z-10 w-96 h-96 relative rounded-3xl"
      >
        <img
          src={AboutProfile}
          alt="Kartik Gurjar"
          className="absolute top-0 left-0 w-full h-full object-cover z-20"
          loading="lazy"
        />
      </div>
      <div
        className="absolute bottom-0 right-0 w-[74%] rounded-bl-xl z-10 rounded-tl-full p-2"
        onClick={() =>
          window.open(
            "https://www.linkedin.com/in/kartik-gurjar-55b6451a5/",
            "_blank"
          )
        }
      >
        <h1 className="text-themeGreen text-3xl ml-10 font-medium">
          Kartik Gurjar
        </h1>
        <h1 className="text-sm text-[#e7e6e6] ml-10">
          Founder & Creative Head
        </h1>
        <span className="absolute bottom-4 right-0 h-12 w-12 rounded-full bg-themeGreen flex items-center justify-center transition-transform duration-300 ease-in-out hover:rotate-45">
          <GoArrowUpRight className="text-4xl text-black" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="bg-lightBlack mt-44 transition-all pt-24 pb-24 duration-300 ease-in-out">
      <div
        className={`h-full w-full flex flex-row justify-evenly items-center ${
          isExpanded ? "overflow-hidden " : " "
        }`}
      >
        <div className="h-[80%] w-[40%] ml-0 ">
          <p className="flex flex-row w-full text-5xl font-bold tracking-wide">
            <span className="text-[#e7e6e6]">
              <strong className="text-themeGreen">Founder’s </strong> note
            </span>
          </p>

          {text
            .slice(0, isExpanded ? text.length : 2)
            .map((paragraph, index) => (
              <p
                key={index}
                className="text-lg font-medium text-wrap mt-7 tracking-wide text-[#cacaca]"
              >
                {paragraph.content}
              </p>
            ))}

          {isButtonVisible && (
            <div className="flex justify-center mt-5">
              <button
                onClick={toggleVisibility}
                className="bg-white h-16 w-16 rounded-full flex items-center justify-center"
              >
                <GoArrowDown className="text-5xl" />
              </button>
            </div>
          )}
        </div>
        {FounderImage()}
      </div>
    </div>
  );
};

export default Aboutdetail;
