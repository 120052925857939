import React from "react";
import Solutionwork from "../components/Solutionslashon/Solutionwork";
import Solutiondetail from "../components/Solutionslashon/Solutiondetail";
import SolutionColb from "../components/Solutionslashon/SolutionColb";
import Solutionhero from "../components/Solutionslashon/Solutionhero";
import MetaDecorator from "../components/MetaDecorator";

const Solutions = () => {
  return (
    <div className="relative overflow-hidden">
      <MetaDecorator
        title="Branding, Design, and Digital Solutions | Slashon Agency Services"
        // desc="Discover our creative services – from brand innovation to playful social media visuals and minimalist concepts. We redefine identities with thoughtful solutions."
        desc="Our Design, Branding and Growth solutions are what your brand needs for transformative results."
      />
      <Solutionhero />
      <Solutionwork />
      <Solutiondetail />
      <SolutionColb />
    </div>
  );
};

export default Solutions;
