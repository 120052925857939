import React from "react";
import ProjectHero from "../components/projects/ProjectHero";
import ProjectsGroup from "../components/projects/ProjectsGroup";
import ImageCarousel from "../components/projects/ImageCarousel";
import ProjectCollab from "../components/projects/ProjectCollab";
import MetaDecorator from "../components/MetaDecorator";

const Projects = () => {
  return (
    <div className="relative overflow-hidden">
      <MetaDecorator
        title="Our Projects | Unique Brand Experiences & Creative Solutions"
        // desc="Explore our portfolio of quirky, creative projects where individuality meets design. View how Slashon Agency builds experiences that breathe life into brands."
        desc="Dig into our range of client works which includes…"
      />
      <ProjectHero />
      <ProjectsGroup />
      <ImageCarousel />
      <ProjectCollab />
    </div>
  );
};

export default Projects;
