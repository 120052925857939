import React, { useEffect, useState } from "react";
import imga from "../../assets/About Page gifs/img1.png";
import imgb from "../../assets/About Page gifs/img2.png";
import imgc from "../../assets/About Page gifs/img3.png";
import imgd from "../../assets/About Page gifs/img4.png";
import VerticalTextCarousel from "../common/VerticalTextCaraousal";

const Abouthero = () => {
  const [imglength, setImglength] = useState(0);
  const images = [imga, imgb, imgc, imgd];

  useEffect(() => {
    const imgDuration = setInterval(() => {
      setImglength((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(imgDuration);
  }, [images.length]);

  return (
    <>
      <div className="h-[106vh] w-full bg-lightBlack flex flex-col tablet:flex-row justify-evenly items-center relative">
        {/* bluegreen gradient */}
        <div className="absolute h-[100%] w-[100%] top-[-50%] right-[-64%] blur-[768px] rounded-full bg-themebluegreen" />

        {/* Text Section */}
        <div className="text-center flex flex-col md:mt-28 lg:mt-0 ">
          <div className="flex flex-col md:flex-row text-white w-[100%] justify-center items-center tablet:justify-start gap-x-3 sm:gap-x-6">
            {/* Increase font size for normal screens */}
            <h1 className="flex-col text-4xl sm:text-7xl md:text-7xl phone:text-5xl lg:text-8xl intermediate:text-8xl phone:6xl tablet:text-7xl font-semibold">
              We are
            </h1>
            <p className="text-sm w-[100%] sm:text-base md:text-base phone:text-base text-center md:text-start xl:w-[45%] table:text-xl lg:text-xl xl:text-2xl md:w-[40%] mt-2 md:mt-0">
              a team of Dope Designers,
              <br className="block md:hidden" /> Bold Brand makers & Mindful
              Marketers
            </p>
          </div>
          <div className="mt-5 xl:mt-0">
            <VerticalTextCarousel
              textList={[
                "Chaotic/Calm",
                "Silly/Smart",
                "Wise/Witty",
                "Serious/Sassy",
              ]}
              fontSize="text-[40px] md:text-8xl text-center xl:text-start lg:text-9xl"
            />
          </div>
        </div>

        {/* Image Section */}
        <div className="w-[35vh] h-[40vh] relative mt-8 md:mt-0">
          {images.map((img, id) => (
            <img
              key={id}
              src={img}
              alt="img"
              className={`absolute top-0   w-full h-full duration-500 ease-in-out ${
                imglength === id ? "opacity-100" : "opacity-0"
              }`}
              loading="lazy"
            />
          ))}
        </div>
      </div>

      <div className="absolute h-[25%] w-[35%] top-[9%] left-[-26%] blur-[192px] rounded-full hidden md:block z-10 bg-themeYellow"></div>
      <div className="absolute h-[25%] w-[30%] top-[30%] right-[-10%] blur-[192px] rounded-full bg-[#3DD0FF29]"></div>
    </>
  );
};

export default Abouthero;
