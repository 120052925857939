import React from "react"
import chaiCharcha from "../../assets/HomeIcons/chaiCharcha.png"
import { OutlinedArrowButton } from "../common/outlineArrowButton"
import { useNavigate } from "react-router-dom";
import RouteNames from "../../RouteNames";

const ChaiCharcha = () => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col xl:flex-row justify-center items-center mt-24 xl:mt-0 xl:py-44 px-5 gap-20">
      <div className=" xl:w-[511px] xl:h-[511px]">
        <img
          src={chaiCharcha}
          alt="Tea and coffee conversation"
          className="h-full w-full rounded-full"
          loading="lazy"
          />
      </div>
      
      <div>
        <h2 className="font-bold text-[35px] text-center xl:text-start xl:text-[72px] leading-none">
          Let's have some <br />
          <span className="text-themeBlue">Chai Pe Charcha!</span>
        </h2>
        <h3 className="font-medium text-center xl:text-start xl:text-2xl mb-6 xl:mb-14 mt-3">
          We also don't mind Coffee & Conversations :P
        </h3>
        <div className="flex justify-center items-center xl:block mb-20 xl:mb-0">
          <OutlinedArrowButton
            buttonText="Let's Collaborate"
            className={"py-[8px] px-[20px]"}
            onClick={() => {
              navigate(RouteNames.CONTACT_US);
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ChaiCharcha
