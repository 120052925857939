import React, { useState } from "react";
import { GoArrowDown, GoArrowUpRight } from "react-icons/go";
import AboutProfile from "../../assets/About Page gifs/Aboutprofile.png";
import { text } from "../../data/Aboutdata.js";

const Aboutdetailmobile = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setIsButtonVisible(false);
  };

  return (
    <div className="bg-lightBlack mt-44 transition-all pt-24 pb-24 duration-300 ease-in-out">
      <div
        className={`h-full flex flex-row justify-center items-center ${
          isVisible ? "overflow-hidden" : ""
        }`}
      >
        <div className="h-[80%] w-[85%] md:w-[80%] ml-0 flex flex-col justify-center items-center">
          <h1 className="flex w-full justify-center text-5xl font-bold tracking-wide">
            <span className="text-[#e7e6e6]">
              <strong className="text-themeGreen">Founder’s </strong> note
            </span>
          </h1>
          <div className="rounded-2xl relative my-20">
            <div
              style={{
                clipPath:
                  "polygon(50% 0%, 100% 0, 100% 35%, 100% 78%, 41% 78%, 25% 100%, 0 100%, 0% 70%, 0% 35%, 0 0)",
              }}
              className="bg-themelightblack z-10 w-96 h-96 relative rounded-3xl"
            >
              <img
                src={AboutProfile}
                alt="Kartik Gurjar"
                className="absolute top-0 left-0 w-full h-full object-cover"
                loading="lazy"
              />
            </div>

            <div
              className="absolute bottom-0 right-0 w-[74%] rounded-bl-xl rounded-tl-full p-2"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/kartik-gurjar-55b6451a5/",
                  "_blank"
                )
              }
            >
              <h1 className="text-themeGreen text-3xl ml-10 font-medium">
                Kartik Gurjar
              </h1>
              <h1 className="text-sm text-[#e7e6e6] ml-10">
                Founder & Creative Head
              </h1>
              <span className="absolute bottom-4 right-2 h-12 w-12 rounded-full bg-themeGreen flex items-center justify-center">
                <GoArrowUpRight className="text-4xl text-black" />
              </span>
            </div>
          </div>
          <div className="flex flex-col items-start">
            {text.slice(0, isVisible ? text.length : 1).map((para, index) => (
              <p
                key={index}
                className="text-lg font-medium text-wrap mt-7 tracking-wide text-[#cacaca]"
              >
                {para.content}
              </p>
            ))}
          </div>

          {isButtonVisible && (
            <div className="flex justify-center mt-5">
              <button
                onClick={toggleVisibility}
                className="bg-white h-16 w-16 rounded-full flex items-center justify-center"
              >
                <GoArrowDown className="text-5xl" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Aboutdetailmobile;
