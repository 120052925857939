import React from "react";
import img1 from "../../assets/ProjectsAssets/CollabImg1.png";
import img2 from "../../assets/ProjectsAssets/CollabImg2.png";
import img3 from "../../assets/ProjectsAssets/CollabImg3.png";
import img4 from "../../assets/ProjectsAssets/CollabImg4.png";
import TextMarquee from "../common/TextMarquee";
import { OutlinedArrowButton } from "../common/outlineArrowButton";
import { useNavigate } from "react-router-dom";
import RouteNames from "../../RouteNames";
const ProjectSectionDetail = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="  flex flex-col items-center justify-start mt-40 mb-32">
        <div className="flex flex-col justify-center items-center">
          <h1 className="font-bold md:text-5xl text-3xl">
            We know you <strong className="text-themeBlue">liked it!</strong>
          </h1>
          <OutlinedArrowButton
            buttonText={"Let’s Collaborate"}
            onClick={() => {
              navigate(RouteNames.CONTACT_US);
            }}
            className={"py-[8px] px-[12px] mt-12"}
          />
        </div>
        <div className="flex justify-between mt-3 gap-24 phone:gap-x-32 sm:gap-x-44 md:gap-x-52 lg:gap-x-64 xl:gap-x-96  ">
          <div className="flex">
            <img
              src={img1}
              alt="layera"
              className=" w-[70px] phone:w-[90px] sm:w-[120px] md:w-[140px] lg:w-[160px] xl:w-[180px] "
              loading="lazy"
            />
            <img
              src={img2}
              alt="layera"
              className="w-[80px] phone:w-[100px] sm:w-[130px] md:w-[150px] lg:w-[170px] xl:w-[200px]"
              loading="lazy"
            />
          </div>
          <div className="flex">
            <img
              src={img3}
              alt="layera"
              className="w-[80px] phone:w-[100px] sm:w-[130px] md:w-[150px] lg:w-[170px] xl:w-[200px]"
              loading="lazy"
            />
            <img
              src={img4}
              alt="layera"
              className="w-[70px] phone:w-[90px] sm:w-[120px] md:w-[140px] lg:w-[160px] xl:w-[180px]"
              loading="lazy"
            />
          </div>
        </div>

        <TextMarquee
          text="LETS CREATE TOGETHER. "
          className=" mt-12 xl:mt-20"
        />
      </div>
    </>
  );
};

export default ProjectSectionDetail;
