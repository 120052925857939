import React from "react";
import LetsConnect from "../components/contact/LetsConnect";
import FAQs from "../components/contact/FAQs";
import MetaDecorator from "../components/MetaDecorator";

const ContactUs = () => {
  return (
    <div className="overflow-hidden  relative">
      <MetaDecorator
        title="Contact Us | Get in Touch with Slashon Agency"
        // desc="Reach us at Slashon Agency for branding and creative solutions. Visit us at Worli, Mumbai, or connect via +91 6359541927 or hey@slashon.agency."
        desc="We’d love to hear more about you and what can we add for your brand’s growth."
      />
      <div className="absolute h-[50%] w-[50%] top-[-25%] right-[-20%]  blur-[210px] rounded-full bg-themebluegreen"></div>
      {/* themeYellow gradient  */}
      <div className="absolute h-[63%] w-[63%] top-[50%] left-[-35%]  blur-[192px] rounded-full bg-themeYellow"></div>
      <LetsConnect />
      <FAQs />
    </div>
  );
};

export default ContactUs;
