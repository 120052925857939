import React, { useState, useEffect } from "react";
import Aboutdetail from "../components/Aboutslashon/Aboutdetail";
import Aboutdetailmobile from "../components/Aboutslashon/Aboutdetailmobile";
import Aboutgroup from "../components/Aboutslashon/Aboutgroup";
import Aboutcont from "../components/Aboutslashon/Aboutcont";
import Abouthero from "../components/Aboutslashon/Abouthero";
import MetaDecorator from "../components/MetaDecorator";

const AboutUs = () => {
  const [isLgScreen, setIsLgScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLgScreen(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="relative overflow-hidden">
      <MetaDecorator
        title="About Us | Our Unique Approach to Branding – Slashon Agency"
        // desc="Learn about our core values and unique perspective in shaping brand identities. At Slashon Agency, we mix playfulness with purpose to create impactful branding experiences."
        desc="Slashon brings a perfect blend of design and strategy to level up your brand and grow against market competencies."
      />
      <Abouthero />
      <Aboutgroup />
      {isLgScreen ? <Aboutdetail /> : <Aboutdetailmobile />}
      <Aboutcont />
    </div>
  );
};

export default AboutUs;
