import vid1 from "../assets/Home page work gifs/propertyexperts.webm";
import vid2 from "../assets/Home page work gifs/cashkaro.webm";
import vid3 from "../assets/Home page work gifs/Motherdairy.webm";
import vid4 from "../assets/Home page work gifs/marksafety.webm";

import propertyExpertImg1 from "../assets/projectSection/ProjectPropertyExpert/imgPropertyExperta.png";
import propertyExpertImg2 from "../assets/projectSection/ProjectPropertyExpert/imgPropertyExpertb.png";
import propertyExpertImg3 from "../assets/projectSection/ProjectPropertyExpert/imgPropertyExpertc.png";
import propertyExpertImg4 from "../assets/projectSection/ProjectPropertyExpert/imgPropertyExpertd.png";
import propertyExpertImg5 from "../assets/projectSection/ProjectPropertyExpert/imgPropertyExpertf.png";
import propertyExpertImg6 from "../assets/projectSection/ProjectPropertyExpert/imgPropertyExperte.png";

import cashkaroImg1 from "../assets/projectSection/ProjectCashKaro/imgCashKaroa.png";
import cashkaroImg2 from "../assets/projectSection/ProjectCashKaro/imgCashKarob.png";
import cashkaroImg3 from "../assets/projectSection/ProjectCashKaro/imgCashKaroc.png";
import cashkaroImg4 from "../assets/projectSection/ProjectCashKaro/imgCashKarod.png";
import cashkaroImg5 from "../assets/projectSection/ProjectCashKaro/imgCashKarog.png";
import cashkaroImg6 from "../assets/projectSection/ProjectCashKaro/imgCashKaroh.png";
import cashkaroImg7 from "../assets/projectSection/ProjectCashKaro/imgCashKaroi.png";
import cashkaroImg8 from "../assets/projectSection/ProjectCashKaro/imgCashKaroj.png";

import cashkaroImg9 from "../assets/projectSection/ProjectCashKaro/imgCashKaroq.png";
import cashkaroImg10 from "../assets/projectSection/ProjectCashKaro/imgCashKaror.png";
import cashkaroImg11 from "../assets/projectSection/ProjectCashKaro/imgCashKaros.png";
import cashkaroImg12 from "../assets/projectSection/ProjectCashKaro/imgCashKarok.png";
import cashkaroImg13 from "../assets/projectSection/ProjectCashKaro/imgCashKarol.png";

import motherDairyImg1 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairya.png";
import motherDairyImg2 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairyb.png";
import motherDairyImg3 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairyc.png";
import motherDairyImg4 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairyd.png";
import motherDairyImg5 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairye.png";
import motherDairyImg6 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairyf.png";
import motherDairyImg7 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairyg.png";
import motherDairyImg8 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairyh.png";

import marksafetyImg1 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetya.png";
import marksafetyImg2 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyb.png";
import marksafetyImg3 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyl.png";
import marksafetyImg4 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyc.png";
import marksafetyImg5 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyd.png";
import marksafetyImg6 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetye.png";
import marksafetyImg7 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyf.png";
import marksafetyImg8 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyg.png";
import marksafetyImg9 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyh.png";
import marksafetyImg10 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyi.png";

import marksafetyGif from "../assets/projectSection/ProjectMarkSafety/imgMarkSafety.gif";

export const projectData = [
  {
    id: 1,
    company: "Property Experts",
    industry: "Real Estate",
    companySize: "11-50 Employees",
    description:
      "Assisting Estate Agents in delivering top-notch service for their clients",
    video: vid1,
    categories: ["Web Design", "Branding"],
    work: [
      "Logo Design",
      "Brand Messaging",
      "Social Media",
      "Brand Identity",
      "Google & Meta Ads",
    ],
    path: "propertyexperts",
    projectImages: [
      propertyExpertImg1,
      propertyExpertImg2,
      propertyExpertImg3,
      propertyExpertImg4,
      propertyExpertImg5,
      propertyExpertImg6,
    ],
  },
  {
    id: 2,
    company: "CashKaro",
    industry: "Fintech",
    companySize: "500-1000 Employees",
    description:
      "India's Largest Cashback App with over 1500+ online shopping sites",
    video: vid2,
    categories: ["Web Design", "Branding"],
    work: [
      "Email Marketing",
      "Brand Messaging",
      "Art Direction",
      "Social Media",
    ],
    path: "cashkaro",
    projectImages: [
      cashkaroImg1,
      cashkaroImg2,
      cashkaroImg3,
      cashkaroImg4,
      cashkaroImg5,
      cashkaroImg6,
      cashkaroImg7,
      cashkaroImg8,
      cashkaroImg9,
      cashkaroImg10,
      cashkaroImg11,
      cashkaroImg12,
      cashkaroImg13,
    ],
  },
  {
    id: 3,
    company: "Mother Dairy",
    industry: "FMCG",
    companySize: "1K-2K Employees",
    description: "Created personalised product for ICC Worldcup's Schedule",
    video: vid3,
    categories: ["Web Design", "Branding"],
    work: ["Creative Design", "Brand Messaging", "Brand Strategy"],
    path: "motherdairy",
    projectImages: [
      motherDairyImg1,
      motherDairyImg2,
      motherDairyImg3,
      motherDairyImg4,
      motherDairyImg5,
      motherDairyImg6,
      motherDairyImg7,
      motherDairyImg8,
    ],
  },
  {
    id: 4,
    company: "Mark Safety",
    industry: "Fire & Safety",
    companySize: "11-50 Employees",
    description: "Deploying world-class industrial fire & safety equipment",
    video: vid4,
    categories: ["Web Design", "Branding"],
    work: [
      "Logo Design",
      "Brand Messaging",
      "Website Design",
      "Brand Identity",
      "Google & Meta Ads",
      "Creative Campaign",
    ],
    path: "marksafety",
    projectImages: [
      marksafetyImg1,
      marksafetyImg2,
      marksafetyImg3,
      marksafetyImg4,
      marksafetyImg5,
      marksafetyImg6,
      marksafetyImg7,
      marksafetyImg8,
      marksafetyImg9,
      marksafetyImg10,
      marksafetyGif,
    ],
  },
];
